import LineViewer from 'components/LineViewer';
import LineViewerChild from 'components/LineViewerChild';
import React, { /*ReactElement,*/ useCallback, useEffect, /*useRef,*/ useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, /*useLocation,*/ useParams } from 'react-router';
import { usePoll, Button, ButtonWithIcon, Content, Icon, Label, PageHeader, SelectField, Spinner, /*Tab, TabContent, TabList, Tabs,*/ useModal, useNotification,/* usePoll*/ } from 'scorer-ui-kit';
import { /*getSingleCameraDetails,*/ pinnedUpdate } from '../services/apiConfig';
import { SpinnerContainer } from 'style';
import styled from 'styled-components';
import { Divider } from 'Styles';
import TokenService from 'services/tokenService';
//import { AlertIcon/*, CAMERA_DETAILS_STATUS_GET_INTERVAL*/, STATUS_ALERT_DETAILS, USB_STATUS_ALERT_DETAILS } from '../constants';
//import { IAlertDetails } from 'interface';
import { /*getStatusCategory,*/ getStatusColor, /*getTimeSinceFromSeconds*/ } from 'utils';
import { AreaChart/*, Line, LineChart,*/, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Area } from 'recharts';
import moment from 'moment';
//import { useDispatch } from 'react-redux';
//import { setPinnedIdOnClick } from 'services/actions';


const Header = styled.div`
  width: 940px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.2;
  > div:first-child > div > h1 {
    max-width: 650px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px !important;
  }
  @media only screen and (max-width: 1400px) {
    width: 825px !important;
    > div:first-child > div > h1 {
      max-width: 600px;
    }
  }
`;
const StyledDivider = styled(Divider)`
  max-width: 940px;
`;
const StyledTopDivider = styled(Divider)`
max-width: 940px;
margin-bottom: 15px;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px 0;
  & > button {
    width: 100%;
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  margin-top: -16px;
`;
const DetailsTable = styled.table`
  margin-bottom: 60px;
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  & tr, td {
    border: 1px solid #eee;
  }
  & > tbody > tr > td {
    height: 100px;
  }  
  & > tbody > tr > td:first-child {
    vertical-align: middle;
    text-align: center;
    width: 7%;
  }
  & > tbody > tr > td:last-child {
    width: 0%;
    padding: 0px;
  }
`;
const Heading = styled(Label)`
  margin-bottom: 10px;
  margin-left: 25px; 
  & > span {
    font-size: 16px;
    color: #5a6269;
  }
`;
const HeadingAreaConfig = styled(Label)`
  margin-bottom: 10px;
  & > span {
    font-size: 16px;
    color: #5a6269;
  }
`;
const DescriptionDiv = styled.div<IDescriptionDiv>`
  font-size: 12px;
  position: relative;
  overflow-wrap: anywhere;
  white-space: break-spaces;
  ${({width}) => width && `width: ${width};`}
  & > label {
    margin-bottom: 0;
    & > span {
      margin-bottom: 0;
      color: #5a6269;
      font-size: 12px;  // Tien
    }
  }
  
  ${({ isStatus, statusColor }) => isStatus && `
    &:before {
      content: '';
      width: 3px;
      height: 10px;
      position: absolute;
      left: -13px;
      top: 75%;
      transform: translateY(-50%);
      border-radius: 5px;
      background: ${statusColor};
    }
  `}
`;
const DescriptionDivContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 45px;
  flex-direction: row;
  margin-left: 25px;
`;
const AreaContainer = styled.span`
  height: 15px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
  	&:hover {
      border-bottom: 1px solid #767676;;
      box-sizing: border-box;
      width: fit-content;
    }
`;
const GraphTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const GraphDataDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const GraphStatusDiv = styled.div<IDescriptionDiv>`
  font-size: 12px;
  position: relative;
  overflow-wrap: anywhere;
  white-space: break-spaces;
  ${({width}) => width && `width: ${width};`}
  & > label {
    margin-bottom: 0;
    & > span {
      margin-bottom: 0;
      color: #5a6269;
    }
  }
  
  ${({ isStatus, statusColor }) => isStatus && `
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 155px;
      top: 33%;
      transform: translateY(-50%);
      border-radius: 0px;
      background: ${statusColor};
    }
  `}
`;
const SmallCameraDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;
const LeftGroupButton = styled.div`
  width: 30%;
`;
const CenterGroupButton = styled.div`
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const RightGroupButton = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const LeftStreamDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const RightGraphDiv = styled.div`
 display: flex;
 flex-direction: column;
 margin-left: 30px;
`;
const CameraDetailsPanel = styled.div`
  width: 940px;
  img {
    border-radius: 5px;
  }
`;
const CameraDetailsBelowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const SingleCameraLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label)`
  margin-bottom: 10px;
  width: 50%;
  display: flex;
  flex-direction: row;
  left: -15px;
  position: relative;
`;
const StyledHoursLabel = styled(Label)`
  margin-bottom: 10px;
  width: 50%;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;
const StyledDetectionLabel = styled(Label)`
  margin-bottom: 10px;
  margin-right: 22px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
`;
const CameraDetailsRightPanel = styled.div`
  width: 540px;
  max-height: 450px;
  position: relative;
  img {
    border-radius: 3px;
  }
  @media screen and (max-width: 1400px) {
    width: 420px;
    > div:first-child {
      width: 420px;
    }
  }
`;
const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
`;
const StyledButton = styled(Button)`
  align-items: center;
  width: fit-content;
  font-size: 13px;
  height: 25px;
`;
const ViewButton = styled(Button)`
  border: none;
  text-decoration: underline;
  background-color: transparent;
  height: auto;
  font-size: 13px;
  font-weight: 400;
  background-image: none !important;
  padding: 0;
  color: hsla(207,80.2%,64.3%,1.000);
  &:hover {
    text-decoration: none;
    background: none !important;
  }
  margin-left: 5px;
  margin-bottom: 2px;
`;
const ViewButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  & > label {
  margin-bottom: 0;
  & > span {
    margin-bottom: 0;
    color: #5a6269;
    font-size: 12px;
  }
  }
`;
const NoData = styled.div`
  width: 570px;
  height: 300px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const ZoomButtonContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  & > div {
    display: flex;
  }
  :hover {
    color: #5aaeea;
    cursor: pointer;
    > div > svg > g {
      stroke: #5aaeea;
    }
  }
  right: 0;
  bottom: 10px;
  margin-left: 145px;
  margin-top: 10px;
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
`;
const OverviewContainer = styled.div`
  width: 940px;
  margin-top: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;
const MainContent = styled.div`
  margin-top: 10px;
  @media only screen and (max-width: 1400px) {
    width: 825px !important;
  }
`;
const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
  font-style: unset !important;
`;
const ButtonWithIconFormatter = styled(ButtonWithIcon)`
  font-size: 13px;
  font-weight: 500;
  padding: 0px 5px 0px 5px;
  border: 1px solid hsla(120,1.3%,85.3%,1.000);
  & > div > div:nth-child(1) {
    padding-left: 10px;
    padding-right: 10px;
  }
  & > div > div:nth-child(2) {
    padding-left: 5px;
  }
  background-color: hsl(0deg 0% 97.48%);
`;

const StyledLineViewer = styled.div`
  margin-top: 20px;
`;

const StyledIconLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > div > svg:first-child {
    position: relative;
    left: -25px;
  }
`;

const StyledLabelAlgorithm = styled(Label)`
  color: hsl(200deg 49.09% 72.86%);
  font-size: 12px;
  position: relative;
  top: -20px;
  margin-bottom: unset;
`;

const StyledAlgorithmText = styled.div`
  font-size: 15px;
  position: relative;
  top: -15px;
`;

interface IParams {
  streamName: string;
}
interface IDescriptionDiv {
  isStatus?: boolean;
  statusColor?: string;
  width?: string;
}
interface ILine {
  y: any;
  x: any;
  name: string,
  idx: number,
  points: {
    x: number,
    y: number,
  }
}
interface ILineConfiguration {
  name: string | undefined;
  lines: ILine[],
  analysis_enabled: boolean,
  points: ILine[],
  id: string
}
interface IUsbDeviceStatus {
  status_code: number
}
interface ICameraDetails {
  camera_name: string,
  stream_name: string,
  notes: string,
  line_configuration: ILineConfiguration[],
  usb_device_status?: IUsbDeviceStatus,
  status?: {
    status_code: number,
    status_category: 'running' | 'error' | 'warning' | 'disabled'
  },
  latest_activity_time?: number,
}
interface ICameraDetailsStatus {
  status_code: number,
  status_category: 'running' | 'error' | 'warning' | 'disabled'
}
const initialCameraDetails: ICameraDetails = {
  camera_name: 'Camera Name',
  stream_name: '',
  notes: '-',
  line_configuration: [],
};
export interface IDeviceDetailsParam{
  selectedTab: string | null
}

const CameraDetails: React.FC = () => {
  const history = useHistory();
  const userName = TokenService.getUser();
  const { goBack } = useHistory();
  const { isModalOpen } = useModal();
  const { streamName } = useParams<IParams>();
  const { t } = useTranslation(['CommonDict']);
  const { sendNotification } = useNotification();

  const [boundingBoxes/*, setBoundingBoxes*/] = useState(JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string));
  const [isStopFrame, setIsStopFrame] = useState(false);
  const [usbDeviceStatus, ] = useState<IUsbDeviceStatus | undefined>(undefined);
  const [isMediaAvailable, setIsMediaAvailable] = useState<boolean>(false);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);
  const [cameraDetailsStatus, ] = useState<ICameraDetailsStatus | undefined>(undefined);

  const [showImage, setShowImage] = useState<boolean>(false);
  const [modalState, setModalState] = useState<boolean>(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const [isListButtonActive, setIsListButtonActive] = useState(false);

  const [dataDetails, setDataDetails] = useState<any>({});
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>(initialCameraDetails);
  const [areaNameList, setAreaNameList] = useState<any[]>([]);
  const [areaName, setAreaName] = useState<string>('');
  const [alertsCount, setAlertsCount] = useState<any>(0);
  const [latestRecordTime, setlAtestRecordTime] = useState<any>('-');
  const [cameraStatus, setIsCameraStatus] = useState<any>('-');
  const [algorithmType, setAlgorithmType] = useState<string>('');
 
  const [pinnedId, setPinnedId] = useState<any>('');
  const [pinnedName, setPinnedName] = useState<any>('');
  const [firstVisit, setFirstVisit] = useState<boolean>(true);

  //const [allCameraData, setAllCameraData] = useState([]);


  useEffect(()=>{
    setIsStopFrame(isModalOpen ? true : false);
    return () => {};
  }, [isModalOpen]);


  const getDetails = useCallback(async () => {
    setIsDataFetched(false);
    const allCameraDataStr: string | null = localStorage.getItem('allCameraData');

    if (allCameraDataStr !== null) {
      const allCameraData = JSON.parse(allCameraDataStr);
      const allAreaDatas = allCameraData.filter((item: any) => item.camera_name === streamName)[0];
      if (!allAreaDatas.line_configuration[0]){
        history.push(`/cameras/camera-configuration/${streamName}`);
        return;
      }

      setCameraDetails(allAreaDatas);
      setAlgorithmType(allAreaDatas.algorithm_type);
      setIsCameraStatus(allAreaDatas.status.status_category);
      setPinnedId(allAreaDatas.pinned_area[0]);
      setPinnedName(allAreaDatas.pinned_area[1]);
      setAreaNameList(allAreaDatas.line_configuration.map((name: any) => name.name));

      let areaData = {'is_reported_count':'', 'latest_record_time': '', 'name': ''};
      if (firstVisit){
        areaData = allAreaDatas.line_configuration.filter((item: any) => item.id === allAreaDatas.pinned_area[0])[0];
        setFirstVisit(false);
      }else{
        areaData = allAreaDatas.line_configuration.filter((item: any) => item.id === dataDetails.id)[0];
      }
      setDataDetails(areaData);
      setAlertsCount(areaData.is_reported_count);
      setlAtestRecordTime(areaData.latest_record_time);
      setAreaName(areaData.name);
      setIsDataFetched(true);
    }
  }, [firstVisit, dataDetails, streamName, history]);  
  /*useEffect(() => {
    getDetails();
  }, [getDetails]); */
  usePoll(async () => {
    await getDetails();
  }, (1000 * 60));
  

  //-- handle Pin --//
  const handlePinnedMiniGraph = useCallback(async (e: any) => {
    const id = e.target.value;
    const pinnedName = cameraDetails.line_configuration.filter((item: any) => item.id === id)[0].name;
    setPinnedId(id);
    setPinnedName(pinnedName);
  
    try {
      const payload = { streamName, id };
      const res = await pinnedUpdate(payload);
      if(res.status === 200) {
        const new_cameraDetails = {
          ...cameraDetails,
          pinned_area: [id, pinnedName],
          line_configuration: cameraDetails.line_configuration.map((item: any) => 
            item.id === id ? {...item, pinned_flg: 1} : {...item, pinned_flg: 0}
          )
        };
        const new_dataDetails = new_cameraDetails.line_configuration.find((item: any) => item.id === id);
        setCameraDetails(new_cameraDetails);
        setDataDetails(new_dataDetails);
        const allCameraDataStr: string | null = localStorage.getItem('allCameraData');
        if (allCameraDataStr !== null) {
          const allCameraData = JSON.parse(allCameraDataStr);
          const updatedDatas = allCameraData.map((item: any) => 
            item.stream_name === streamName ? new_cameraDetails : item
          );
          localStorage.setItem('allCameraData', JSON.stringify(updatedDatas));
        }
      }else{
        sendNotification({type: 'error', message: 'Failed to Pin Mini Graph'});
      }
    } catch (err) {
      sendNotification({type: 'error', message: 'Failed to Pin Mini Graph'});
    }
  },[cameraDetails, streamName, sendNotification]);
  //------------------//


  //-- handle View and scroll --//
  const handleView = useCallback( () => {
    const tmpData = cameraDetails.line_configuration.filter((item: any) => item.id === pinnedId);
    if(pinnedId) {
      setDataDetails(tmpData.find((item: any) => item.id === pinnedId));
      const areaElement = document.getElementById(pinnedId);
      if(areaElement) {
        areaElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    } else {
      setDataDetails((tmpData)[0]);
      const areaElement = document.getElementById(tmpData[0].id);
      if(areaElement) {
        areaElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  },[cameraDetails, pinnedId]);
  //------------------//



  //-- List Button --//
  const handleListClick = () => {
    setIsDropdownVisible(false);
    setIsListButtonActive(true);

    const allCameraDataStr: string | null = localStorage.getItem('allCameraData');
    if (allCameraDataStr !== null) {
      const allCameraData = JSON.parse(allCameraDataStr);
      const filteredData = allCameraData.filter((item: any) => item.camera_name === streamName)[0];
      setAlertsCount(filteredData.stream_is_reported_count);
      setlAtestRecordTime(filteredData.latest_record_time);
    }
  };

  // dropdown
  const handleChange = (e: any) => {
    const oneAreaData: any = cameraDetails.line_configuration.find((item: any) => item.name === e);
    setDataDetails(oneAreaData);
    setAlertsCount(oneAreaData.is_reported_count);
    setlAtestRecordTime(oneAreaData.latest_record_time);
  };  

  //-- Single Button --//
  const handleSingleClick = useCallback(() => {
    setIsDropdownVisible(true);
    setIsListButtonActive(false);
  },[]);
  //------------------//



  // -- common function --//
  function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  const formatXAxis = (date: any) => {
    return moment(date).format('HH') + t('H');
  };

  const onZoom = useCallback(() => {
    setModalState(true);
    setShowImage(true);
  }, []);  

  const goToPreviousPage = () => {
    goBack();
  };
  
  const omMediaLoaded = useCallback((isMediaLoaded: boolean) => {
    setIsMediaAvailable(isMediaLoaded);
  }, []);

  const goToConfig = useCallback(() => {
    setFirstVisit(true);
    history.push(`/cameras/camera-configuration/${streamName}`);
  }, [history, streamName]);
  //----------------------//


  return (
    <>
      {isDataFetched ? (
        <Content>
          <Header>
            <PageHeaderComponent>
              <AreaContainer onClick={goToPreviousPage}>{t('Cameras')}</AreaContainer>
              <PageHeader
                title={cameraDetails.camera_name}
                icon='DevicesScorerCamera'
                updateDocTitle={false}
              />
              <StyledLabelAlgorithm htmlFor='' labelText=''>Algorithm Type: </StyledLabelAlgorithm>
              <StyledAlgorithmText>{algorithmType.toUpperCase()}</StyledAlgorithmText>
            </PageHeaderComponent>  
            <ButtonContainer>
              {TokenService.getUserType() &&
                <>
                  <ButtonWithIcon disabled={usbDeviceStatus?.status_code === 30700 ? true : false} icon='Analyse' size='small' position='left' design='secondary' onClick={() => goToConfig()}>{t('Configure Analysis')}</ButtonWithIcon>
                </>}
            </ButtonContainer>
          </Header>
          <MainContent>
            <OverviewContainer>
              <CameraDetailsPanel>
                <StyledIconLabelContainer>
                  <Icon icon='Information' size={15} color='dimmed' />
                  <StyledLabel htmlFor='' labelText=''>Overview</StyledLabel>
                </StyledIconLabelContainer>
                <StyledTopDivider />
                <DetailsTable>
                  <tbody>
                    <tr>
                      <td><Icon icon='DevicesScorerCamera' size={20} color='dimmed' /></td>
                      <td>
                        <Heading htmlFor='cameraDetails' labelText={t('Camera Details')} />
                        <DescriptionDivContainer>
                          <DescriptionDiv
                            isStatus width='55px' statusColor={
                              (!cameraDetailsStatus || !cameraDetailsStatus.status_category) ? getStatusColor('')
                                : getStatusColor(cameraDetailsStatus.status_category)
                            }
                          >
                            <div>{t('Detection')}</div>
                            <Label htmlFor='statusCamera' labelText={cameraStatus} />
                          </DescriptionDiv>

                          <DescriptionDiv isStatus={false}>
                            <div>{t('Latest Activity')}</div>
                            <Label htmlFor='statusLatest' labelText={latestRecordTime} />
                          </DescriptionDiv>

                          <DescriptionDiv isStatus={false}>
                            <div>{t('Total Areas')}</div>
                            <Label
                              htmlFor='statusLatest' labelText={(areaNameList.length).toString()}
                            />
                          </DescriptionDiv>

                          <DescriptionDiv isStatus={false}>
                            <div>{t('Pinned Mini Graph')}</div>
                            <ViewButtonContainer>                                 
                              <Label
                                htmlFor='statusLatest' labelText={pinnedName}
                              />
                              {(!pinnedName || pinnedName === '') ? <ViewButton disabled onClick={handleView}>(View)</ViewButton> : <ViewButton onClick={handleView}>(View)</ViewButton>}
                            </ViewButtonContainer>
                          </DescriptionDiv>

                          <DescriptionDiv isStatus={false}>
                            <div>{t('Alerts (in 24h)')}</div>
                            <Label htmlFor='statusLatest' labelText={alertsCount} />
                          </DescriptionDiv>
                        </DescriptionDivContainer>
                      </td>
                      <td>
                        <SmallCameraDiv>
                          <LineViewerChild
                            streamName={streamName}
                            height='106px'
                            onMediaLoadedCallback={omMediaLoaded}
                            boundingBoxes={boundingBoxes}
                            isStopFrame={isStopFrame}
                            showImage={showImage}
                            setShowImage={setShowImage}
                          />
                        </SmallCameraDiv>
                      </td>
                    </tr>                        
                  </tbody>
                </DetailsTable>

                <Row>
                  <LeftGroupButton>
                    <HeadingAreaConfig htmlFor='' labelText={t('Area Configurations')} />
                  </LeftGroupButton>

                  {isDropdownVisible &&
                    <CenterGroupButton>
                      <StyledSelectField
                        changeCallback={handleChange}
                        value={isEmpty(dataDetails) ? areaName : dataDetails.name}
                        disabled={false}
                        isCompact     
                      >
                        {
                          areaNameList.map((item: any, index: any) => {
                            return (                                
                              <option key={item + index} value={item}>
                                {item}
                              </option>
                            );
                          })
                        }
                      </StyledSelectField>
                    </CenterGroupButton>}
                  
                    
                  <RightGroupButton width={isListButtonActive ? '70%' : '20%'}>
                    <ButtonWithIconFormatter 
                      icon='Analyse'
                      design={!isListButtonActive  ? 'primary' : 'secondary'}
                      onClick={handleSingleClick}
                      size='small'
                      position='left'
                    >{t('Single')}
                    </ButtonWithIconFormatter>

                    <ButtonWithIconFormatter 
                      icon='LayoutList'
                      design={isListButtonActive  ? 'primary' : 'secondary'}
                      onClick={handleListClick}
                      size='small'
                      position='left'
                    >{t('List')}
                    </ButtonWithIconFormatter>
                  </RightGroupButton>
                </Row>


                {!isListButtonActive ? 
                  <CameraDetailsBelowDiv>
                    <SingleCameraLeftContainer>
                      <LeftStreamDiv>
                        <StyledIconLabelContainer>
                          <Icon icon='MetaCategories' size={15} color='dimmed' />
                          <StyledLabel htmlFor='' labelText=''>{t('Area')}: {isEmpty(dataDetails) ? areaName : dataDetails.name}</StyledLabel>
                        </StyledIconLabelContainer>
                        <StyledDivider />
                        <StyledLineViewer>
                          <LineViewer
                            streamName={streamName}
                            height='unset'
                            onMediaLoadedCallback={omMediaLoaded}
                            linesData={isEmpty(dataDetails) ? [] : [{'points': dataDetails.points}]}
                            isModal={modalState}
                            onImageClick={onZoom}
                            boundingBoxes={boundingBoxes}
                            isStopFrame={isStopFrame}
                            showImage={showImage}
                            setShowImage={setShowImage}
                          />
                        </StyledLineViewer> 
                        {
                          isMediaAvailable &&
                            <ZoomButtonContainer onClick={onZoom}>
                              <Icon icon='Search' size={14} color='dimmed' />
                              <div>{t('Zoom')}</div>
                            </ZoomButtonContainer>
                        } 
                      </LeftStreamDiv>
                    </SingleCameraLeftContainer>
                  
                    <RightGraphDiv>
                      <GraphTitleDiv>
                        <Icon weight='regular' icon='Date' color='dimmed' size={15} />
                        <StyledHoursLabel htmlFor='' labelText=''>Last 24 Hours</StyledHoursLabel>
                        <GraphStatusDiv
                          isStatus width='5px' statusColor={
                            (!cameraDetailsStatus || !cameraDetailsStatus.status_category) ? getStatusColor('')
                              : getStatusColor(cameraDetailsStatus.status_category)
                          }
                        />
                        <StyledDetectionLabel htmlFor='' labelText=''>Detections</StyledDetectionLabel>                          
                      </GraphTitleDiv>
                      <StyledDivider />                          
                      
                      <GraphDataDiv>
                        <CameraDetailsRightPanel>
                          {dataDetails.graph_data === undefined || dataDetails.graph_data.length === 0 ?                                
                            <NoData>No Data</NoData>                                     
                            :
                            <>
                              <AreaChart
                                width={570}
                                height={300}
                                data={isEmpty(dataDetails) ? [] : dataDetails.graph_data}
                                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                              >
                                <CartesianGrid stroke='#f0f0f0' />
                                <XAxis interval={50} stroke='hsl(207,5%,57%)' dx={6} dy={4} tickLine={false} dataKey='record_time' tickFormatter={formatXAxis} />
                                <YAxis 
                                  stroke='hsl(207,5%,57%)' dx={-2} tickLine={false}
                                  tickFormatter={(value) => dataDetails.analysis_type.toLowerCase() === 'ratio' ? `${Math.round(value)}%` : value}
                                  domain={[
                                    0, 
                                    dataDetails.analysis_type.toLowerCase() === 'ratio' ?
                                      Math.max(
                                        Math.max(...dataDetails.graph_data.map((item: any) => item.ratio_value)),
                                        dataDetails.segmentation_threshold + 1
                                      )
                                      :
                                      Math.max(
                                        Math.max(
                                          ...dataDetails.graph_data.map((item: any) => dataDetails.is_use_segmentation_area === 1 ? item.segmentation_count : item.area_count)
                                        ), 
                                        dataDetails.detection_threshold + 1
                                      )
                                  ]}
                                />
                                <Tooltip />
                                <Area
                                  type='monotone' stroke='#97d3f2' fill='#97d3f2'
                                  dataKey={
                                    dataDetails.analysis_type.toLowerCase() === 'ratio' ? 'ratio_value'
                                      : (dataDetails.analysis_type.toLowerCase() === 'count' && dataDetails.is_use_segmentation_area === 1) ? 'segmentation_count' : 'area_count'
                                  }
                                />
                                <ReferenceLine
                                  stroke='red' strokeDasharray='5 5'
                                  y={dataDetails.analysis_type.toLowerCase() === 'ratio' ? dataDetails.segmentation_threshold : dataDetails.detection_threshold}
                                />
                              </AreaChart>
                              <XAxis>
                                {t('overviewTab.date')}
                              </XAxis>
                            </>}
                        </CameraDetailsRightPanel>
                      </GraphDataDiv>
                      <ButtonDiv>
                        <StyledButton size='small' design='secondary' onClick={handlePinnedMiniGraph} key={dataDetails.id} value={dataDetails.id}>Set as Pinned Mini Graph</StyledButton>
                      </ButtonDiv>
                    </RightGraphDiv>
                  </CameraDetailsBelowDiv> 


                  // ↑ single
                  :
                  // ↓ list


                  cameraDetails.line_configuration.map((item: any, key: number) => {
                    return (
                      <CameraDetailsBelowDiv key={key} id={item.id}>
                        <SingleCameraLeftContainer>
                          <LeftStreamDiv>
                            <StyledIconLabelContainer>
                              <Icon icon='MetaCategories' size={15} color='dimmed' />
                              <StyledLabel htmlFor='' labelText=''>Area: {isEmpty(cameraDetails) ? areaName : item.name}</StyledLabel>
                            </StyledIconLabelContainer>
                            <StyledDivider />
                            <StyledLineViewer>
                              <LineViewer
                                streamName={streamName}
                                height='unset'
                                onMediaLoadedCallback={omMediaLoaded}
                                linesData={isEmpty(cameraDetails) ? [] : [{'points': item.points}]}
                                isModal={modalState}
                                onImageClick={onZoom}
                                boundingBoxes={boundingBoxes}
                                isStopFrame={isStopFrame}
                                showImage={showImage}
                                setShowImage={setShowImage}
                              />
                            </StyledLineViewer>
                            {
                              isMediaAvailable &&
                                <ZoomButtonContainer onClick={onZoom}>
                                  <Icon icon='Search' size={14} color='dimmed' />
                                  <div>{t('Zoom')}</div>
                                </ZoomButtonContainer>
                            } 
                          </LeftStreamDiv>
                        </SingleCameraLeftContainer>
                    
                        <RightGraphDiv>
                          <GraphTitleDiv>
                            <Icon weight='regular' icon='Date' color='dimmed' size={15} />
                            <StyledHoursLabel htmlFor='' labelText=''>Last 24 Hours</StyledHoursLabel>
                            <GraphStatusDiv
                              isStatus width='5px' statusColor={
                                (!cameraDetailsStatus || !cameraDetailsStatus.status_category) ? getStatusColor('')
                                  : getStatusColor(cameraDetailsStatus.status_category)
                              }
                            />
                            <StyledDetectionLabel htmlFor='' labelText=''>Detections</StyledDetectionLabel>                          
                          </GraphTitleDiv>
                          <StyledDivider />
                          
                          
                          <GraphDataDiv>
                            <CameraDetailsRightPanel>
                              {item.graph_data === undefined || item.graph_data.length === 0 ?                                   
                                <NoData>No Data</NoData>
                                :
                                <>                            
                                  <AreaChart
                                    width={570}
                                    height={300}
                                    data={isEmpty(cameraDetails) ? [] : item.graph_data}
                                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                                  >
                                    <CartesianGrid stroke='#f0f0f0' />
                                    <XAxis interval={50} stroke='hsl(207,5%,57%)' dx={6} dy={4} tickLine={false} dataKey='record_time' tickFormatter={formatXAxis} />
                                    <YAxis
                                      stroke='hsl(207,5%,57%)' dx={-2} tickLine={false}
                                      tickFormatter={(value) => item.analysis_type.toLowerCase() === 'ratio' ? `${Math.round(value)}%` : value}
                                      domain={[
                                        0, 
                                        item.analysis_type.toLowerCase() === 'ratio' ?
                                          Math.max(
                                            Math.max(...item.graph_data.map((item: any) => item.ratio_value)),
                                            item.segmentation_threshold + 1
                                          )
                                          :
                                          Math.max(
                                            Math.max(
                                              ...item.graph_data.map((item: any) => item.is_use_segmentation_area === 1 ? item.segmentation_count : item.area_count)
                                            ), 
                                            item.detection_threshold + 1
                                          )
                                      ]}
                                    />
                                    <Tooltip />                                    
                                    <Area
                                      type='monotone' stroke='#97d3f2' fill='#97d3f2'
                                      dataKey={
                                        item.analysis_type.toLowerCase() === 'ratio' ? 'ratio_value'
                                          : (item.analysis_type.toLowerCase() === 'count' && item.is_use_segmentation_area === 1) ? 'segmentation_count' : 'area_count'
                                      }
                                    />
                                    <ReferenceLine
                                      stroke='red' strokeDasharray='5 5'
                                      y={item.analysis_type.toLowerCase() === 'ratio' ? item.segmentation_threshold : item.detection_threshold}
                                    />
                                  </AreaChart>                            
                                  <XAxis>
                                    {t('overviewTab.date')}
                                  </XAxis>
                                </>}
                            </CameraDetailsRightPanel>
                          </GraphDataDiv>
                          <ButtonDiv>
                            <StyledButton size='small' design='secondary' onClick={handlePinnedMiniGraph} key={item.id} value={item.id}>Set as Pinned Mini Graph</StyledButton>
                          </ButtonDiv>
                        </RightGraphDiv>
                      </CameraDetailsBelowDiv>);                        
                  })}
              </CameraDetailsPanel>
            </OverviewContainer>
          </MainContent>
        </Content>
      ) : (
        <SpinnerContainer>
          <Spinner size='medium' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerContainer>
      )}
    </>
  );
};

export default CameraDetails;