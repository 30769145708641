import React, { useCallback, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { usePoll, useMediaModal, Button, ButtonWithLoading, Content, ButtonWithIcon, Icon, Label, LineReducer, LineSetContext, LineUI, SelectField, SmallInput, Spinner, Switch, PageHeader, FilterDropdown, useModal, useNotification } from 'scorer-ui-kit';
import { useHistory, } from 'react-router';
import { Divider } from 'Styles';
import styled, { css } from 'styled-components';
import { getStaticData, getAreaData, saveArea} from 'services/apiConfig';
import { useTranslation } from 'react-i18next';
import BoxModal from 'components/BoxModal';
import { BASE_API_URL, EDGE_API_BASE_URL } from '../../constants';


const MainContent = styled.div`
  margin-top: -5px;
  max-width: 940px !important;
`;

const Header = styled.div`
  width: 100%;
  max-width: 940px !important;
  height: 108px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  line-height: 1.2;
  > div:first-child > div > a {
    max-width: 750px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    bottom: 34.5px;
    top: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CancelButton = styled(Button)`
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;


/*const SettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 9px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;*/

const InputAndButtonContainer = styled.div<{ gap: string }>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;


const DividerWithMargin = styled(Divider)`
  margin: 14px 0 12px;
`;

const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 20px;
`;



const LabelWithMargin = styled(Label)`
  margin-bottom: 10px;
  & > span {
    margin-bottom: 10px;
  }
  font-size: 12px;
`;


const StyledSmallInputWithLabel = styled(SmallInput) <ISmallInput>`
  & > label {
    width: ${({ length }) => length};
    margin-bottom: 0;
    font-size: 12px;
  }
`;



const StyledSmallInput2 = styled(SmallInput) <ISmallInput>`
  width: ${({ length }) => length};
  min-height: 30px;
  & > label {
    margin-bottom: 0;
    font-size: 12px;
  }
  & > label > div {
    width: 100%;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;


const StyledSelectField = styled(SelectField)`
  width: 209px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const LineSettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 20px 0;
`;

// const LineUILeftPanel = styled.div`
//   padding: 15px 0;
//   width: 100%;
//   flex-shrink: 0;
//   position: relative;
// `;

const LineUIImageWrapper = styled.div<{ isReadOnly: boolean }>`
  width: 100%;
  height: 390px;
  position: relative;
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    > img {
      width: max-content;
      box-shadow: -2px 0 0 0 #ddd, 2px 0 0 0 #ddd;
    }
    & > div > svg > g:nth-child(n) {
      > text {
        display:none;
      }
      // > g:nth-child(n) {
      //   > text {
      //     display: none;
      //   }
      // }
    }
    
  }
  ${({ isReadOnly }) => isReadOnly && css`
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  `}
`;


const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.35)'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
`;

const LineUIContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  display: flex;
  margin: 30px 0 15px;
  position: relative;
  border-radius: 3px;
  background-color: #fafcfc;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.05);
  border: solid 1px #eee;
  flex-direction: column;
  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -26px;
    left: -16px;
    border-left: 1px solid #d9dad9;
    border-bottom: 1px solid #d9dad9;
  }
`;


const ButtonContainer = styled.div`
  margin-top: -14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  & > button {
    min-width: 155px;
  }
`;

const LineUILeftPanel = styled.div`
  padding: 15px 0;
  float: left;
  width: 30%;
`;

const LineUIRightPanel = styled.div`
  width: 100%;
  float: right;
  width: 70%;
`;

const LineUIBottomPanel = styled.div`
  padding: 15px 0;
  width: 100%;
  bottom: 0px;
`;

const TargetAndEditContainer = styled.div`
  display: flex;
`;

const EditButton = styled(FilterDropdown)`
  & > div > div > button {
    width: 40px;
    height: 20px;
    & > div > div {
      width: 10px;
      :nth-child(2) { font-size: 10px; }
      :nth-child(3) { display: none; }
    }
  }
  margin-left: 5px;
`;

const ConfirmBox = styled.div`
`;

const ConfirmButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  & > button:first-child{
    margin-right: 10px;
    background: #e4edf4;
  }
`;

const ButtonFormat = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ButtonWithIconFormat = styled(ButtonWithIcon)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ConfirmText = styled(Label)`
  font-size: 20px;
`;

const StyledLabelAlgorithm = styled(Label)`
  color: hsl(200deg 49.09% 72.86%);
  font-size: 12px;
  position: relative;
  top: -20px;
  margin-bottom: unset;
`;

const StyledAlgorithmText = styled.div`
  font-size: 15px;
  position: relative;
  top: -15px;
`;


interface ISmallInput {
  length: string;
}

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

interface IParams {
  streamName: string;
}


const CAMERA_IMAGE_REFRESH_INTERVAL = 5;


const CameraConfiguration: React.FC = React.memo(() => {
  const history = useHistory();
  const { isMediaUrlValid } = useMediaModal();
  const { streamName } = useParams<IParams>();
  const { t } = useTranslation(['CommonDict']);
  const { sendNotification } = useNotification();
  const { createModal, setModalOpen } = useModal();
  const [state, dispatch] = useReducer(LineReducer, []);
  const [state_edit, dispatch_edit] = useReducer(LineReducer, []);

  const [analysisType, setAnalysisType] = useState('');
  const [decisionPoint, setDecisionPoint] = useState<any>({});
  const [segArea, setSegArea] = React.useState<any>('');
  const [segAreaSize, setSegAreaSize] = useState<any>('');
  const [segThreshold, setSegThreshold] = useState<any>('');
  const [detectionThreshold, setDetectionThreshold] = useState<any>('');
  const [alertConTime, setAlertConTime] = useState<any>('');
  const [alertConHoldTime, setAlertConHoldTime] = useState<any>('');
  const [probThreshold, setProbThreshold] = useState<any>('');
  const [minWidth, setMinWidth] = useState<any>('');
  const [maxWidth, setMaxWidth] = useState<any>('');
  const [minHeight, setMinHeight] = useState<any>('');
  const [maxHeight, setMaxHeight] = useState<any>('');
  const [pointNumber, setPointNumber] = useState<any>('');
  const [isAnalysisEnabled, setIsAnalysisEnabled] = useState<boolean>(false);
  const [imageDetails, setImageDetails] = useState({ x: 1920, y: 1080 });
  const [image, setImage] = useState('');
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [showEditArea, setShowEditArea] = useState<boolean>(false);

  const [areaData, setAreaData] = useState<any[]>([]);
  const [selectedAreaID, setSelectedAreaID] = React.useState('');
  const [detectionPoint, setDetectionPoint] = useState([]);
  const [segmentation, setSegmentation] = useState([]);  
  const [objectTarget, setObjectTarget] = useState([]);
  const [selectedObjectTarget, setSelectedObjectTarget] = useState([]);
  const [camAlgorithmType, setCamAlgorithmType] = useState('');
  

  // init ------------------ 
  const getJsonData = useCallback(async() => {
    const allCameraDataStr: string | null = localStorage.getItem('allCameraData');
    if (allCameraDataStr !== null) {
      const allCameraData = JSON.parse(allCameraDataStr);
      const targetObject = allCameraData.find((item: any) => item.stream_name === streamName);
      setCamAlgorithmType(targetObject.algorithm_type);
      
      try{
        const res = await getStaticData({'algorithm_type': targetObject.algorithm_type});
        if (res.status === 200 && res.data) {
          const data: any = res.data.data;
          setDetectionPoint(data.detection_point);
          setSegmentation(data.segmentation);
          setObjectTarget(data.object_target.flatMap((x: string) => ({ text: x, value: x })));
        }

      } catch (error){
        console.log(error);
      }
    }

  }, [streamName]);
  useEffect(() => {
    getJsonData();
  }, [getJsonData]);
  // ---------- 



  // function ------------------ 
  const hideLineUI = useCallback((index: any) => {
    const lineUI = document.getElementById('lineui-id');
    if (lineUI) {
      const svg: any = lineUI.querySelector('svg');
      if (svg) {
        for (let i = 0; i < svg.children.length; i++) {
          if (index) {
            if (i !== Number(index)) {
              svg.children[i].style.display = 'none';
            } else {
              svg.children[i].style.display = '';

            }
          } else {
            svg.children[i].style.display = '';
          }
        }
      }
    }
  }, []);

  function isEmpty(obj: any) {
    for (const x in obj) {
      return false;
    }
    return true;
  }
  // ---------- 



  
  // step 3 ------------------ 
  // -------- show Area Data ----------
  useEffect(() => {
    const index = Number(selectedAreaID);
    if (areaData[index]) {
      setAnalysisType(areaData[index].analysis_type);
      setProbThreshold(areaData[index].probThreshold);
      setMinWidth(areaData[index].minWidth);
      setMaxWidth(areaData[index].maxWidth);
      setMinHeight(areaData[index].minHeight);
      setMaxHeight(areaData[index].maxHeight);
      setSegArea(areaData[index].segArea);
      setDecisionPoint(areaData[index].decisionPoint);
      setSegAreaSize(areaData[index].segAreaSize);
      setSegThreshold(areaData[index].segThreshold);
      setDetectionThreshold(areaData[index].detectionThreshold);
      setAlertConTime(areaData[index].alertConTime);
      setAlertConHoldTime(areaData[index].alertConHoldTime);
      setIsAnalysisEnabled(areaData[index].enabled);
      setPointNumber(t('Area Sides')+(areaData[index].points.length));

      const targetArray = areaData[index].target;
      const arr = objectTarget.filter(function (o: any) { return targetArray.includes(o.value); });
      setSelectedObjectTarget(arr);

      if (state.length){
        const new_state = JSON.parse(JSON.stringify(state[index]));
        dispatch_edit({
          type: 'LOAD',
          state: [new_state],
        });
      }
    }
  }, [areaData, selectedAreaID, objectTarget, t, state]);
  // --------


  // step 2 ------------------ 
  // -------- select edit area ----------
  const selectEditArea = useCallback((e) => {
    setSelectedAreaID(e);
    hideLineUI(e);
  }, [hideLineUI]);
  // --------


  // step 1 ------------------ 
  // -------- get Area Data ----------
  const fetchAreas = useCallback(async () => {
    if (streamName) {
      try {
        const res: any = await getAreaData({camName: streamName});
        if (res.status === 200 && res.data) {
          const data: any = res.data.data;
          if (!isEmpty(data)) {
            setAreaData(data);
            dispatch({
              type: 'LOAD',
              state: data,
            });
          }

          // エリアをクリックしたら、そのエリアが選択せれる
          const lineUI = document.getElementById('lineui-id');
          if (lineUI) {
            const svg: any = lineUI.querySelector('svg');
            if (svg) {
              for (let i = 0; i < svg.children.length; i++) {
                svg.children[i].id = i;
                svg.children[i].onclick = function() {
                  selectEditArea(this.id.toString());
                };
              }
            }
          }

        }
      }
      catch (error) {
        console.log(error);
      }
    }
  }, [streamName, selectEditArea]);
  useEffect(() => {
    fetchAreas();
  }, [fetchAreas]);
  // --------


  // step 4 ------------------ 
  // -------- handle Area config ----------
  const handleCameraEnable = useCallback((e: boolean) => {
    const index = Number(selectedAreaID);
    const new_areaData = JSON.parse(JSON.stringify(areaData));
    new_areaData[index].enabled = e;
    setIsAnalysisEnabled(e);
    setAreaData(new_areaData);
  }, [selectedAreaID, areaData]);

  const handleAnalysisType = useCallback((e) => {
    const index = Number(selectedAreaID);
    const new_areaData = JSON.parse(JSON.stringify(areaData));
    new_areaData[index].analysis_type = e;
    setAnalysisType(e);
    setAreaData(new_areaData);
  }, [selectedAreaID, areaData]);

  const handleRatioConfig = useCallback((e, type) => {
    const index = Number(selectedAreaID);
    const new_areaData = JSON.parse(JSON.stringify(areaData));
    if (type === 'selectSegArea'){
      const found: any = segmentation.find((obj: any) => {return obj.name === e;});
      new_areaData[index].segArea = e;
      new_areaData[index].segPixelVal = found ? found.formula.value : [];
      setSegArea(e);
    }
    else if (type === 'setSegThreshold'){
      const value = e > 100 ? 100 : e < 0 ? 1 : Number(e);
      new_areaData[index].segThreshold = value ;
      setSegThreshold(value);
    }
    else if (type === 'setAlertConTime'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].alertConTime = value ;
      setAlertConTime(value);
    }
    else if (type === 'setAlertConHoldTime'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].alertConHoldTime = value ;
      setAlertConHoldTime(value);
    }
    setAreaData(new_areaData);
  }, [selectedAreaID, areaData, segmentation]);

  const handleCountConfig = useCallback((e, type) => {
    const index = Number(selectedAreaID);
    const new_areaData = JSON.parse(JSON.stringify(areaData));
    if (type === 'selectSegArea'){
      const found: any = segmentation.find((obj: any) => {return obj.name === e;});
      new_areaData[index].segArea = e;
      new_areaData[index].segPixelVal = found ? found.formula.value : [];
      setSegArea(e);
    }
    else if (type === 'setSelectedObjectTarget'){
      new_areaData[index].target = e ? e.map((item: any) => item.text) : [];
      setSelectedObjectTarget(e);
    }
    else if (type === 'setDecisionPoint'){
      new_areaData[index].decisionPoint = e;
      setDecisionPoint(e);
    }
    else if (type === 'setSegAreaSize'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].segAreaSize = value ;
      setSegAreaSize(value);
    }
    else if (type === 'setSegThreshold'){
      const value = e > 100 ? 100 : e < 0 ? 1 : Number(e);
      new_areaData[index].segThreshold = value ;
      setSegThreshold(value);
    }
    else if (type === 'setDetectionThreshold'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].detectionThreshold = value ;
      setDetectionThreshold(value);
    }
    else if (type === 'setProbThreshold'){
      const value = e > 100 ? 100 : e < 0 ? 1 : Number(e);
      new_areaData[index].probThreshold = value ;
      setProbThreshold(value);
    }
    else if (type === 'setMinWidth'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].minWidth = value ;
      setMinWidth(value);
    }
    else if (type === 'setMaxWidth'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].maxWidth = value ;
      setMaxWidth(value);
    }
    else if (type === 'setMinHeight'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].minHeight = value ;
      setMinHeight(value);
    }
    else if (type === 'setMaxHeight'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].maxHeight = value ;
      setMaxHeight(value);
    }
    else if (type === 'setAlertConTime'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].alertConTime = value ;
      setAlertConTime(value);
    }
    else if (type === 'setAlertConHoldTime'){
      const value = e < 0 ? 1 : Number(e);
      new_areaData[index].alertConHoldTime = value ;
      setAlertConHoldTime(value);
    }
    setAreaData(new_areaData);
  }, [selectedAreaID, areaData, segmentation]);
  // --------


  // step 5 ------------------ 
  // -------- save config ----------
  const saveAreas = useCallback(async () => {
    for (let i = 0; i < areaData.length; i++) {
      if (areaData[i].name === '' || areaData[i].name == null) {
        sendNotification({ type: 'error', message: t('Name cannot be empty.') });
        return;
      }
    }
    
    setSaveLoading(true);
    setIsReadOnly(true);

    const new_areaData = areaData.map((item, index) => {
      return { ...item, points: state[index].points };
    });

    try {
      const response = await saveArea({'areaData': new_areaData, streamName});
      if (response.data.status_code !== 200) {
        sendNotification({ type: 'error', message: response.data.message });
      }else{
        if(response.status === 200) {
          sendNotification({ type: 'success', message: t('Saved area successfully.') });
          // 編集後のarea pointsを即反映
          try {
            const allCameraDataStr: string | null = localStorage.getItem('allCameraData');
            if (allCameraDataStr !== null) {
              const allCameraData = JSON.parse(allCameraDataStr);
              const new_allCameraData = allCameraData.map((item: any) => {
                if (item.camera_name === streamName) {
                  const updatedLineConfiguration = item.line_configuration.map((lineItem: any) => {
                    const matchingArea = new_areaData.find((area: any) => area.areaName === lineItem.id);
                    if (matchingArea) {
                      return { ...lineItem, points: matchingArea.points };
                    } else {
                      return lineItem;
                    }
                  });
                  return { ...item, line_configuration: updatedLineConfiguration };
                } else {
                  return { ...item };
                }
              });
              localStorage.setItem('allCameraData', JSON.stringify(new_allCameraData));
            }
          } catch (error) {
            console.error((error as Error).message);
          }
        } else {
          sendNotification({ type: 'error', message: t('Failed to save.') });
        }
      }
    } catch (error) {
      console.log(error);
      sendNotification({ type: 'error', message: t('Failed to save.') });
    }
    setSaveLoading(false);
    setIsReadOnly(false);
  }, [state, areaData, streamName, sendNotification, t]);
  // --------


  // step other ------------------ 
  // -------- handle area ----------
  const editAreaName = (e: any) => {
    const index = Number(selectedAreaID);
    const new_areaName = e.target.value;
    dispatch({
      type: 'RENAME_SET',
      index: index,
      data: {
        name: new_areaName,
      },
    });

    const new_areaData = JSON.parse(JSON.stringify(areaData));
    new_areaData[index].name = new_areaName;
    setAreaData(new_areaData);
  };

  const addArea = useCallback(async () => {
    const id = 'id' + Math.random().toString(16).slice(2);
    //const num = (state.length + 1).toString();
    const data = {
      'name': 'area_' + id,
      'areaName': id,
      'points': [
        { x: 0, y: 0 },
        { x: 500, y: 0 },
        { x: 500, y: 200 },
        { x: 0, y: 200 },
      ],
      'target': [],
      'analysis_type': 'count',
      'decisionPoint': detectionPoint[0],
      'probThreshold': 50,
      'minWidth': 1,
      'minHeight': 1,
      'maxWidth': 100,
      'maxHeight': 100,
      'isUseSegArea': 0,
      'segArea': '利用なし',
      'segPixelVal': [],
      'segAreaSize': 1,
      'segThreshold': 50,
      'alertConTime': 60,
      'alertConHoldTime': 360,
      'detectionThreshold': 1,
      'enabled': false
    };
    dispatch({
      type: 'ADD_SET',
      data
    });

    
    const new_areaData = JSON.parse(JSON.stringify(areaData));
    new_areaData.push(data);
    selectEditArea((state.length).toString());
    setAreaData(new_areaData);
  }, [state, areaData, selectEditArea, detectionPoint]);

  const removeArea = useCallback(async (index) => {
    const areaId = state[Number(selectedAreaID)].areaName;
    try {
      axios.post(BASE_API_URL + '/removeArea', {areaId})
        .then(() => {
          setModalOpen(false);
          setSelectedAreaID('');
          dispatch({
            type: 'REMOVE_SET',
            index,
          });
          sendNotification({ type: 'success', message: t('Remove Area Successfully') });
        })
        .catch((error) => {
          setModalOpen(false);
          console.error(error);
          sendNotification({ type: 'error', message: t('Failed to remove area.') });
        });
    } catch (error) {
      setModalOpen(false);
      console.log(error);
      sendNotification({ type: 'error', message: t('Failed to remove area.') });
    }
  }, [state, selectedAreaID, setModalOpen, sendNotification, t]);

  const addPoint = useCallback(async (index) => {
    if (state[index].points.length >= 50) return;
    setPointNumber(t('Area Sides')+(state[index].points.length+1));
    dispatch({
      type: 'ADD_POINT',
      index,
    });
  }, [state, t]);

  const removePoint = useCallback(async (index) => {
    if (state[index].points.length <= 3) return;
    setPointNumber(t('Area Sides')+(state[index].points.length-1));
    dispatch({
      type: 'REMOVE_POINT',
      index,
    });
  }, [state, t]);


  const getCameraImage = useCallback(async () => {
    if (streamName) {
      try {
        const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}/stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
        if (res.status === 200 && res.data) {
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          const isImageValid = await isMediaUrlValid(imgBase64, 'img');
          if (isImageValid === true) {
            const img = new Image();
            img.src = imgBase64;
            setImage(imgBase64);
            setImageDetails({
              x: img.naturalWidth,
              y: img.naturalHeight
            });
            setImageLoading(false);
          } else {
            setImageLoading(false);
          }
        } else {
          setImageLoading(false);
        }
      } catch (err) {
        setImageLoading(false);
        console.error(err);
      }
    }
  }, [streamName, isMediaUrlValid]);

  usePoll(async () => {
    getCameraImage();
  }, CAMERA_IMAGE_REFRESH_INTERVAL * 1000);

  const handleCancel = useCallback(() => {
    history.push(`/cameras/camera-details/${streamName}/overview`);
  }, [history, streamName]);

  const getConfirm = useCallback(() => (
    <ConfirmBox>
      <ConfirmText htmlFor='' labelText={t('Are you sure to delete this area')} />
      <ConfirmButtonBox>
        <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => setModalOpen(false)}>{t('Close')}</ButtonFormat>
        <ButtonWithIconFormat tabIndex={0} design='primary' icon='Delete' position='left' onClick={() => removeArea(selectedAreaID)}>{t('Delete')}</ButtonWithIconFormat>
      </ConfirmButtonBox>
    </ConfirmBox>
  ), [setModalOpen, removeArea, selectedAreaID, t]);

  const onClickConfirm = useCallback(() => {
    createModal({
      isCloseEnable: true,
      width: '580px',
      padding: true,
      closeText: 'CLOSE',
      customComponent: getConfirm()
    });
  }, [createModal, getConfirm]);

  const SaveEditArea = useCallback(() => {
    const indexToUpdate = state.findIndex(item => item.name === state_edit[0].name);
    const new_state = state.map((item, index) =>
      index === indexToUpdate ? state_edit[0] : item
    );
    dispatch({
      type: 'LOAD',
      state: new_state,
    });
    setShowEditArea(false);
  }, [state, state_edit]);



  return (
    <Content>
      <Header>
        <PageHeaderComponent>
          <PageHeader
            areaTitle={t('Configuration')}
            icon='Analyse'
            updateDocTitle={false}
            title={streamName}
          />
          <StyledLabelAlgorithm htmlFor='' labelText=''>Algorithm Type: </StyledLabelAlgorithm>
          <StyledAlgorithmText>{camAlgorithmType.toUpperCase()}</StyledAlgorithmText>
        </PageHeaderComponent>
        <ButtonContainer>
          <CancelButton
            design='secondary'
            size='small'
            onClick={handleCancel}
          >
            {t('Cancel')}
          </CancelButton>
          <ButtonWithLoading design='primary' loading={saveLoading} disabled={selectedAreaID === '' || state.length === 0 || isReadOnly} size='small' onClick={saveAreas}>
            {t('Save Changes')}
          </ButtonWithLoading>
        </ButtonContainer>
      </Header>
      <MainContent>
        <LineSettingsContainer>
          <h5>{t('Area Configuration')}</h5>
          <LineSelectFieldContainer>
            <Icon icon='MetaCategories' size={16} color='dimmed' />
            <StyledSelectField
              changeCallback={(e)=>selectEditArea(e)}
              disabled={isReadOnly}
              isCompact
              value={selectedAreaID ? Number(selectedAreaID) : ''}
              placeholder={t('Choose an area...')}
            >
              {
                state.map((item: any, index: any) => {
                  return (
                    <option key={index} value={index}>
                      {item.name}
                    </option>
                  );
                })
              }
            </StyledSelectField>
            <InputAndButtonContainer gap='0 10px'>
              <ButtonWithIcon
                icon='Add'
                position='left'
                size='small'
                design='secondary'
                onClick={addArea}
                disabled={state.length === 20 ||isReadOnly}
              >
                {t('Add Area')}
              </ButtonWithIcon>
              <ButtonWithIcon
                icon='ActionRemove'
                position='left'
                size='small'
                design='secondary'
                onClick={onClickConfirm}
                disabled={selectedAreaID === '' || state.length === 0 || isReadOnly}
              >
                {t('Remove Area')}
              </ButtonWithIcon>
              <ButtonWithIcon
                icon='Edit'
                position='left'
                size='small'
                design='secondary'
                onClick={() => setShowEditArea(true)}
                disabled={selectedAreaID === '' || state.length === 0 || isReadOnly}
              >
                {t('Edit Area')}
              </ButtonWithIcon>
            </InputAndButtonContainer>
          </LineSelectFieldContainer>
          <LineUIContainer>
            {(selectedAreaID !== '') && (
              <div>
                <LineUILeftPanel>
                  <SidePaddingDiv>
                    <StyledSmallInputWithLabel
                      fieldState='default'
                      label={t('Area Name')}
                      name='Area Name'
                      length='150px'
                      maxLength={24}
                      disabled={isReadOnly}
                      value={state[Number(selectedAreaID)].name}
                      onChange={(e) => editAreaName(e)}
                    />
                    <LabelWithMargin
                      htmlFor='pointNumber'
                      labelText={pointNumber}
                      style={{ marginTop: '10px' }}
                    />
                    <ButtonWithIcon
                      icon='Add'
                      position='left'
                      size='xsmall'
                      design='secondary'
                      onClick={() => addPoint(selectedAreaID)}
                      disabled={selectedAreaID === '' || state.length === 20 || isReadOnly}
                    >
                      {t('Add')}
                    </ButtonWithIcon>
                    <ButtonWithIcon
                      icon='ActionRemove'
                      position='left'
                      size='xsmall'
                      design='secondary'
                      onClick={() => removePoint(selectedAreaID)}
                      disabled={selectedAreaID === '' || state.length === 20 || isReadOnly}
                    >
                      {t('Remove')}
                    </ButtonWithIcon>
                    <br />
                    <br />
                    <Switch
                      checked={isAnalysisEnabled}
                      labelText={t(isAnalysisEnabled ? 'Enabled' : 'Disabled')}
                      // labelText={t('Enable Detection')}
                      leftTheme='off'
                      onChangeCallback={(e) => handleCameraEnable(e)}
                      rightTheme='on'
                      state={isReadOnly || !selectedAreaID? 'disabled' : 'default'}
                    />
               
                  </SidePaddingDiv>
                </LineUILeftPanel>









                <LineUIRightPanel>
                  <SidePaddingDiv>
                    <InputAndButtonContainer gap='0 14px'>
                      <div>
                        <LabelWithMargin
                          htmlFor='analysisType'
                          labelText={t('Analysis Type')}
                        />
                        <StyledSelectField
                          changeCallback={(e) => { handleAnalysisType(e); }}
                          disabled={isReadOnly}
                          isCompact
                          value={analysisType}
                          placeholder={t('Choose type...')}
                          style={{ width: '120px' }}
                        >
                          <option key={1} value='Count'>
                            Count
                          </option>
                          <option key={2} value='Ratio'>
                            Ratio
                          </option>
                        </StyledSelectField>
                      </div>










                      {(analysisType.toLowerCase() === 'count') && (
                        <div style={{ minHeight: '60px' }}>
                          <TargetAndEditContainer>
                            <LabelWithMargin
                              htmlFor='analysisTargets'
                              labelText={t('Analysis Targets')}
                            />
                            <EditButton
                              buttonIcon='NoIcon'
                              buttonText='Edit'
                              hasOptionsFilter
                              list={objectTarget}
                              loadingText='ローディング...'
                              maxDisplayedItems={6}
                              onSelect={(e: any) => { handleCountConfig(e, 'setSelectedObjectTarget'); }}
                              optionType='checkbox'
                              searchPlaceholder='Filter targets...'
                              searchResultText='Showing [VISIBLE] of [TOTAL]'
                              selected={selectedObjectTarget}
                            />
                          </TargetAndEditContainer>
                          <div>
                            {selectedObjectTarget ?
                              selectedObjectTarget.map((item: any, index: any) => {
                                return (
                                  <ButtonWithIcon
                                    icon='Analyse'
                                    position='left'
                                    size='xsmall'
                                    design='secondary'
                                    key={index}
                                    value={item.value}
                                    disabled={isReadOnly}
                                  >
                                    {item.text}
                                  </ButtonWithIcon>
                                );
                              })
                              : ''}
                          </div>
                        </div>
                      )}
                    </InputAndButtonContainer>

                    {(analysisType.toLowerCase() === 'count') && (
                      <>
                        <h5>{t('Decision Settings')}</h5>
                        <InputAndButtonContainer gap='0 14px'>
                          <div>
                            <LabelWithMargin
                              htmlFor='segmentation'
                              labelText={t('Segmentation')}
                            />
                            <StyledSelectField
                              changeCallback={(e) => { handleCountConfig(e, 'selectSegArea'); }}
                              // disabled={isReadOnly}
                              isCompact
                              value={segArea}
                              placeholder='Choose segmentation...'
                              style={{ width: '150px' }}
                            >
                              <option value='利用なし'>
                                {t('Not use')}
                              </option>
                              {
                                segmentation.map((item: any, index: any) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              }
                            </StyledSelectField>
                          </div>
                          <div>
                            <LabelWithMargin
                              htmlFor='decisionPoint'
                              labelText={t('Decision Point')}
                            />
                            <StyledSelectField
                              changeCallback={(e) => { handleCountConfig(e, 'setDecisionPoint'); }}
                              placeholder='Choose an option...'
                              // disabled={isReadOnly}
                              isCompact
                              value={decisionPoint}
                              style={{ width: '150px' }}
                            >
                              {
                                detectionPoint.map((item: any, index: any) => {
                                  return (
                                    <option key={index} value={item}>
                                      {JSON.parse(item).name}
                                    </option>
                                  );
                                })
                              }
                            </StyledSelectField>
                          </div>
                          <StyledSmallInputWithLabel
                            fieldState='default'
                            label={t('Decision Size')}
                            name='decisionSize'
                            length='90px'
                            maxLength={24}
                            disabled={isReadOnly}
                            value={segAreaSize}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setSegAreaSize'); }}
                          />
                          <StyledSmallInput2
                            fieldState='default'
                            name='areaThreshold'
                            type='number'
                            min={1}
                            max={100}
                            disabled={isReadOnly}
                            length='200px'
                            label={t('Area Threshold')}
                            unit='%'
                            value={segThreshold}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setSegThreshold'); }}
                          />

                        </InputAndButtonContainer>
                        <DividerWithMargin />
                        <InputAndButtonContainer gap='0 24px'>
                          <StyledSmallInput2
                            fieldState='default'
                            name='countThreshold'
                            type='number'
                            min={1}
                            max={100}
                            disabled={isReadOnly}
                            length='250px'
                            label={t('Count Threshold')}
                            unit={t('unit')}
                            value={detectionThreshold}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setDetectionThreshold'); }}
                          />
                          <StyledSmallInputWithLabel
                            fieldState='default'
                            name='detectionProbability'
                            type='number'
                            min={0}
                            max={100}
                            disabled={isReadOnly}
                            length='150px'
                            label={t('Detection Probability')}
                            unit='%'
                            value={probThreshold}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setProbThreshold'); }}
                          />
                        </InputAndButtonContainer>
                        <DividerWithMargin />
                        <InputAndButtonContainer gap='0 14px'>
                          <StyledSmallInput2
                            fieldState='default'
                            name='minWidth'
                            type='number'
                            min={0}
                            // max={10000}
                            disabled={isReadOnly}
                            length='120px'
                            label={t('Minimum Width')}
                            unit='px'
                            value={minWidth}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setMinWidth'); }}
                          />
                          <StyledSmallInput2
                            fieldState='default'
                            name='maxWidth'
                            type='number'
                            min={0}
                            // max={10000}
                            disabled={isReadOnly}
                            length='120px'
                            label={t('Maximum Width')}
                            unit='px'
                            value={maxWidth}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setMaxWidth'); }}
                          />
                          <StyledSmallInput2
                            fieldState='default'
                            name='minHeight'
                            type='number'
                            min={0}
                            // max={10000}
                            disabled={isReadOnly}
                            length='120px'
                            label={t('Minimum Height')}
                            unit='px'
                            value={minHeight}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setMinHeight'); }}
                          />
                          <StyledSmallInput2
                            fieldState='default'
                            name='maxHeight'
                            type='number'
                            min={0}
                            // max={10000}
                            disabled={isReadOnly}
                            length='120px'
                            label={t('Maximum Height')}
                            unit='px'
                            value={maxHeight}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setMaxHeight'); }}
                          />
                        </InputAndButtonContainer>
                        <DividerWithMargin />
                        <h5>{t('Alert Settings')}</h5>
                        <InputAndButtonContainer gap='0 14px'>
                          <StyledSmallInput2
                            fieldState='default'
                            name='alertConTime'
                            type='number'
                            min={0}
                            // max={10000}
                            disabled={isReadOnly}
                            length='150px'
                            label={t('Duration Trigger Time')}
                            unit='sec'
                            value={alertConTime}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setAlertConTime'); }}
                          />
                          <StyledSmallInput2
                            fieldState='default'
                            name='alertResTime'
                            type='number'
                            min={0}
                            // max={10000}
                            disabled={isReadOnly}
                            length='150px'
                            label={t('Alert Send Cooldown')}
                            unit='sec'
                            value={alertConHoldTime}
                            onChange={(e) => { handleCountConfig(e.target.value, 'setAlertConHoldTime'); }}
                          />
                        </InputAndButtonContainer>
                      </>
                    )}











                    {(analysisType.toLowerCase() === 'ratio') && (
                      <>
                        <div style={{ display: 'flex', gap: '0 14px'}}>
                          <div>
                            <h5>{t('Decision Settings')}</h5>
                            <InputAndButtonContainer gap='0 14px'>
                              <div>
                                <LabelWithMargin
                                  htmlFor='segmentation'
                                  labelText={t('Segmentation')}
                                />
                                <StyledSelectField
                                  changeCallback={(e) => { handleRatioConfig(e, 'selectSegArea'); }}
                                  // disabled={isReadOnly}
                                  isCompact
                                  value={segArea}
                                  placeholder='Choose segmentation...'
                                  style={{ width: '150px' }}
                                >
                                  {
                                    segmentation.map((item: any, index: any) => {
                                      return (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })
                                  }
                                </StyledSelectField>
                              </div>
                              <StyledSmallInput2
                                fieldState='default'
                                name='areaThreshold'
                                type='number'
                                min={1}
                                max={100}
                                disabled={isReadOnly}
                                length='120px'
                                label={t('Area Threshold')}
                                unit='%'
                                value={segThreshold}
                                onChange={(e) => { handleRatioConfig(e.target.value, 'setSegThreshold'); }}
                              />
                            </InputAndButtonContainer>
                          </div>
                          <div>
                            <h5>{t('Alert Settings')}</h5>
                            <InputAndButtonContainer gap='0 14px'>
                              <StyledSmallInput2
                                fieldState='default'
                                name='alertConTime'
                                type='number'
                                min={0}
                                // max={10000}
                                disabled={isReadOnly}
                                length='130px'
                                label={t('Duration Trigger Time')}
                                unit='sec'
                                value={alertConTime}
                                onChange={(e) => { handleRatioConfig(e.target.value, 'setAlertConTime'); }}
                              />
                              <StyledSmallInput2
                                fieldState='default'
                                name='alertResTime'
                                type='number'
                                min={0}
                                // max={10000}
                                disabled={isReadOnly}
                                length='130px'
                                label={t('Alert Send Cooldown')}
                                unit='sec'
                                value={alertConHoldTime}
                                onChange={(e) => { handleRatioConfig(e.target.value, 'setAlertConHoldTime'); }}
                              />
                            </InputAndButtonContainer>
                          </div>
                        </div>
                      </>
                    )}
                    <DividerWithMargin />
                  </SidePaddingDiv>
                </LineUIRightPanel>
              </div>
            )}
            <LineUIBottomPanel>
              <SidePaddingDiv>
                <LineUIImageWrapper isReadOnly={isReadOnly} id='lineui-id'>
                  <LineSetContext.Provider value={{ state, dispatch }}>
                    {imageLoading ?
                      <SpinnerContainer>
                        <Spinner size='large' styling='primary' />
                      </SpinnerContainer>
                      :
                      <div>
                        <LineUI
                          options={{
                            showSetIndex: false,
                            showPoint: true,
                            showMoveHandle: false,
                            showPointHandle: true,
                            setIndexOffset: 0,
                            boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                            fixedImgDimensions: imageDetails
                          }}
                          src={image}
                        />
                      </div>}

                  </LineSetContext.Provider>
                </LineUIImageWrapper>
              </SidePaddingDiv>
            </LineUIBottomPanel>


            {showEditArea &&
              <BoxModal
                closeText={t('CLOSE')}
                isOpen={showEditArea}
                onDismiss={() => SaveEditArea()}
                customeComponent={
                  <ConfirmBox>
                    <ConfirmText htmlFor='' labelText={t('Edit Area')} />
                    <LineUIImageWrapper isReadOnly={false}>
                      <LineSetContext.Provider value={{ 'state': state_edit, 'dispatch': dispatch_edit }}>
                        <LineUI
                          options={{
                            showSetIndex: false,
                            showPoint: true,
                            showMoveHandle: false,
                            showPointHandle: true,
                            setIndexOffset: 0,
                            boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                            fixedImgDimensions: imageDetails
                          }}
                          src={image}
                        />
                      </LineSetContext.Provider>
                    </LineUIImageWrapper>
                    <ConfirmButtonBox>
                      <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => SaveEditArea()}>{t('Close')}</ButtonFormat>
                    </ConfirmButtonBox>
                  </ConfirmBox>
                }
              />}


          </LineUIContainer>
        </LineSettingsContainer>
      </MainContent>
    </Content>
  );
});

export default CameraConfiguration;
