import axios from 'axios';
import api from './api';
import {IAddUser, ISafieSettingData, ITrafficCounterData, IUpdatePassword, IUploader, IExportRequestPayload, IAction } from 'interface';
import { EDGE_API_BASE_URL, BASE_API_URL } from '../constants';
import TokenService from './tokenService';

// Single Camera details
// export const getSingleCameraDetails = (streamName: string) => api.userInstance.get<any>(`/cameras/${streamName}`);

// Single camera configuration
export const getConfigDetails = (streamName: string) => api.userInstance.get<any>(`/cameras/${streamName}/configurations`);
export const setConfigDetails = (streamName: string, jsonData: ITrafficCounterData) => api.userInstance.put<any>(`/cameras/${streamName}/configurations`, {...jsonData, stream_name: streamName});

export const exportSettings = () => axios.get(BASE_API_URL + '/settings/export/');

// safie settings

export const getSafieConfigDetails = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}system/safie/status/all`);
};

export const getSafieStreamDetails = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}stacks/all`);
};

export const setSafieSettings = (jsonData: ISafieSettingData) => {
  return axios.put<any>(`${EDGE_API_BASE_URL}system/safie/all`, jsonData);
};

export const deleteSafieSettings = () => {
  return axios.delete<any>(`${EDGE_API_BASE_URL}/system/safie/all`);
};

export const getSafieConfig = () => {
  return axios.get<any>(EDGE_API_BASE_URL + '/system/safie/all');
};

// cloud uploader settings

export const getDeviceInfo = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}devices/deviceinfo`);
};

export const getCloudStatus = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}devices/cloudstatus`);
};

export const getUploadSettings = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}system/services/uploader`);
};

export const getUploadStatus = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}system/system_services/uploader/status`);
};

export const setUploadSettings = (jsonData: IUploader) => {
  return axios.patch<any>(`${EDGE_API_BASE_URL}system/services/uploader`, jsonData);
};

//Health Status of machine

export const getHealthStatusDetails = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}system/status`);
};

// user management
export const getAllUsers = async () => { 
  return await api.userInstance.get<any>('/users/all');
};

export const addUser = async (data:IAddUser) => {
  return await api.userInstance.put<any>('/users/add', data);
};

export const getSingleUser = async (data:string) => {
  return await api.userInstance.get<any>(`/users/${data}`);
};

export const updateSingleUser = async (userID:string, data: IAddUser) => {
  return await api.userInstance.patch<any>(`/users/${userID}`,data);
};

export const onDeleteUser = async (data: string) => {
  return await api.userInstance.delete<any>(`/users/${data}`);
};

export const updatePassword = async (data:IUpdatePassword) => {
  // return await api.userInstance.patch<any>(`/users/${TokenService.getUserID()}`,data);
  return await api.userInstance.patch<any>(`/users/${TokenService.getUserID()}`,{data,action: 'update_password'});

};

// Camera API
//
export const pinnedUpdate = async (param: any) => {
  return await api.userInstance.post<any>('/cameras/pinned_update', param);
};

export const getViewData = async (stream_name: string) => {
  return await api.userInstance.get<any>(`/view/${stream_name}`);
};
//

export const getAllCamera = async () => {
  return await api.userInstance.get<any>('/cameras/all');
};

export const addCamera = async (data:any) => {
  const camera_name = encodeURIComponent(data.camera_name);
  return await api.userInstance.put<any>(`/cameras/${camera_name}`, data);
};

export const getSingleCamera = async (camera_name: string) => {
  return await api.userInstance.get<any>(`/cameras/${camera_name}`);
};

export const deleteSingleCamera = async (camera_name: string) => {
  return await api.userInstance.delete<any>(`/cameras/${camera_name}`);
};

export const updateCamera = async (data: any) => {
  return await api.userInstance.patch<any>(`/cameras/${data.stream_name}`, data);
};

// Guest User

export const getGuestUser = async () => {
  return await api.userInstance.get<any>('/auth_setting');
};

export const putGuestUpdate = async (data: boolean) => {
  return await api.userInstance.put<any>('/auth_setting', { guest_enabled: data });
};

// scorer USB video device

export const getVideoDevice = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}/devices/videos`);
};

export const getSafieDevice = async () => {
  return await axios.get<any>(`${EDGE_API_BASE_URL}/devices/safie`);
};

// Details Files Tab

export const getDetailsFiles = async (stream_name:string, param:any) => {
  return await api.userInstance.post<any>(`/alerts/${stream_name}/all`, param);
};

export const getDelCameraFile = async (stream_name:string, param:any) => {
  return await api.userInstance.post<any>(`/alerts/${stream_name}/all`, param);
};

//export table data
export const getExportTable = async (param:IExportRequestPayload) => {
  return await api.userInstance.post<any>('alerts/export', param);
};

export const getDownloadFilesUrl = async (param:any, stream_name:string,) => {
  return await api.userInstance.post<any>(`alerts/${stream_name}/all`, param);
};

export const getLastExport = async () => {
  return await api.userInstance.get<any>('alerts/export');
};


//configuration Advance tab

export const setSnapshotMP4Details = async (stream_name: string, param: IAction) => {
  return await api.userInstance.post<any>(`cameras/${stream_name}`, param);
};

//Machine info for user drawer
export const getEdgeDeviceDetails = async () => {
  return await api.userInstance.get<any>('/system/all');
};

//area settings
export const getAreaData = async (params:any) => {
  return await api.userInstance.get<any>('areas', {params});
};

export const saveArea = async (param:any) => {
  return await api.userInstance.post<any>('saveArea', param);
};

//email
export const saveEmail = async (param:any) => {
  return await api.userInstance.post<any>('saveEmail', param);
};

export const getEmail = async () => {
  return await api.userInstance.get<any>('saveEmail', );
};

//get static data from external files
export const getStaticData = async (params:any) => {
  return await api.userInstance.get<any>('json_data', {params});
};



export const getCamList = async () => {
  return await api.userInstance.get<any>('camList');
};


