import React, { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory, useLocation } from 'react-router-dom';
import { Content, PageHeader, Switch, TextField, ButtonWithLoading, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { saveEmail, getEmail } from 'services/apiConfig';

const Container = styled(Content)`
  // @media screen and (min-width: 1920px) {
  //   padding-left: 168px;
  //   padding-right: 168px;
  // }
`;

const HeaderContainer = styled.div`
  margin-bottom: 47px;
  > div:first-child > div > h1 {
    max-width: 650px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px !important;
  }
  & > div > div > div:nth-child(3){
    bottom: 4px;
  }
  & > div > p {
    font-weight: normal;
    font-style: normal;
  }
  position: relative;
  max-width: 940px !important;
`;

const InnerContainer = styled.div`
  margin-left: 1px;
`;


const BackLinkNonHover = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui}; 
  font-size: 12px;
  font-weight: 500;
  color: #7c8793;
  margin-bottom: 4px;
  line-height: 12px;
  text-decoration: none;
`;

const PageHeaderFormatter = styled.div`
  svg {
    margin-top: -7px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 9px;
  align-items: center;
`;

const SubTitle = styled.label`
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: #5a6269;
  margin: 0;
  padding: 0;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 2px;
`;

const LabelText = styled.div`
  font-size: 14px;
  width: 200px;
  font-weight: 500;
  color: rgba(120, 138, 144, 0.72);
  margin: 0 25px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  padding: 0;
  line-height: 1.4;
`;

const TextFieldDiv = styled(TextField)`
  width: 450px;
`;

const UnderInputText = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui}; 
  font-size: 12px;
  font-weight: 300;
  color: #7c8793;
  margin-bottom: 4px;
  line-height: 20px;
  text-decoration: none;
  span {
    display: block;
  }
`;

const LastBox = styled.div`
  margin-top: 8px;
  position: absolute;
  right: 10px;
  top: 21px;
`;

const EmailAlert: FC = () => {
  const { t } = useTranslation(['CommonDict']);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const { sendNotification } = useNotification();
  const [isAlertEnabled, setIsAlertEnabled] = useState<boolean>(true);


  const onGetEmail = useCallback(async () => {
    try {
      const response = await getEmail();
      if(response.status === 200) {
        setEmailAddress(response.data.emails);
        setIsAlertEnabled(response.data.isAlertEnabled);
      }
    }
    catch {
      sendNotification({ type: 'error', message: 'Failed to get emails.' });
    }
  }, [sendNotification]);
  useEffect(() => {
    onGetEmail();
  }, [onGetEmail]);


  const onCameraToggle = useCallback((e: boolean) => {
    setIsAlertEnabled(e);
  }, []);

  const onSaveEmail = useCallback(async () => {
    const emailRegex = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
    if (emailRegex.test(emailAddress)){
      const payload = {
        emailAddress,
        isAlertEnabled
      };
      try {
        const response = await saveEmail(payload);
        if(response.status === 200) {
          setSaveLoading(false);
          setIsReadOnly(false);
          sendNotification({ type: 'success', message: t('Saved email successfully.') });
        } else {
          setSaveLoading(false);
          setIsReadOnly(false);
          sendNotification({ type: 'error', message: t('Failed to save.') });
        }
      }
      catch {
        setSaveLoading(false);
        setIsReadOnly(false);
        sendNotification({ type: 'error', message: t('Failed to save.') });
      }
    } else {
      sendNotification({ type: 'error', message: t('Please enter emails correctly') });
    }
  }, [emailAddress, sendNotification, t, isAlertEnabled]);

  
  

  return (
    <Container>
      <HeaderContainer>
        <BackLinkNonHover>{t('Settings')}</BackLinkNonHover>
        <PageHeaderFormatter>
          <PageHeader
            title={t('Email Alert')}
            icon='ViewSettings'
            introductionText={t('Settings related to email alerts generated by the system')}
            updateDocTitle={false}
          />
        </PageHeaderFormatter>
        <LastBox>
          <ButtonWithLoading design='primary' onClick={onSaveEmail} loading={saveLoading} size='small'>{t('Save Changes')}</ButtonWithLoading>
        </LastBox>
      </HeaderContainer>
      <InnerContainer>
        <>
          <FlexContainer>
            <SubTitle>{t('Email Alert')}</SubTitle>
          </FlexContainer>
          <FlexColumn style={{'marginTop': '27px'}}>
            <LabelText>{t('Enabled')}</LabelText>
            <Switch
              checked={isAlertEnabled}
              leftTheme='off'
              onChangeCallback={(e) => onCameraToggle(e)}
              rightTheme='on'
            //   state={isReadOnly ? 'disabled' : 'default'}
            />
          </FlexColumn>
          <FlexColumn>
            <LabelText style={{'marginTop': '17px'}}>{t('Alert Recipient Email')}</LabelText>
            <div>
              <TextFieldDiv
                feedbackMessage='This is a feedback message.'
                fieldState='default'
                label=''
                name='my_input'
                placeholder=''
                onChange={(e) => { setEmailAddress(e.target.value); }}
                disabled={isReadOnly}
                defaultValue={emailAddress}
              />
              <UnderInputText><span>{t('if you wish to send to multiple email addresses. Please use a comma separated list.')}</span><span>{t('For example,')} {t('user1@example.com, user2@example.com')}</span></UnderInputText>
            </div>
          </FlexColumn>
        </>
      </InnerContainer>
        
    </Container>
  );
};

export default EmailAlert;